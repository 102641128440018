<template>
  <div class="sctp-bg-white">
    <el-alert
      type="warning"
      :closable="false"
      description="请上传实名认证一致的微信收款二维码和支付宝收款二维码，如果非本人将审核不通过。平台将在48小时内审核完成"
      show-icon>
    </el-alert>
    <div class="sctp-pad15">
      <el-row :gutter="10">
        <template v-for="(item,key) in uploadConfig.data">
          <el-col :span="12">
            <div class="sctp-tc">
              <div>
                <div class="sctp-tc sctp-mar-b10 fz-18">{{ item.label }}</div>
              </div>
              <el-upload
                :http-request="fileUploadFunction"
                :disabled="item.auth === 1"
                :data="{...uploadConfig.uploadEnum.USER_COLLECTION_CODE}"
                :show-file-list="false"
                action=""
                :on-error="uploadConfig.uploadFileError"
                :on-success="uploadConfig.onUploadSuccess.bind(null, {key: key})"
                :before-upload="uploadConfig.beforeUpload.bind(null, {key: key})"
                drag>
                <template v-if="!item.path">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </template>
                <template v-else>
                  <el-image
                    style="height: 100%;width: 100%;"
                    fit="contain"
                    :src="item.preview"
                  ></el-image>
                </template>
                <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且文件大小不超过1M</div>
              </el-upload>
              <div v-if="item.auth !== 1" class="sctp-tc sctp-pad-t15">
                <el-button
                  type="primary"
                  size="small"
                  @click="item.onSubmit"
                >
                  <template v-if="item.reUpload">
                    重新上传
                  </template>
                  <template v-else>
                    上传
                  </template>
                </el-button>
              </div>
              <div class="sctp-tc sctp-pad-t15">
                <div>{{ ['待审核', '审核通过', '审核不通过'][item.auth] }}</div>
                <div v-if="item.auth === 2">审核不通过原因：{{ item.reason }}</div>
              </div>
            </div>
          </el-col>
        </template>
      </el-row>
    </div>
  </div>
</template>

<script>
import {user} from "../../../../apis";
import uploadEnum from "@/resources/js/uploadenum";

const config = require('../../../../resources/js/config');
export default {
  name: "collectionCode",
  data() {
    return {
      uploadConfig: {
        data: {
          wxCode: {
            auth: 0,
            reason: '',
            path: '',
            reUpload: false,
            label: '微信收款码',
            onSubmit: () => {
              if (!this.uploadConfig.data.wxCode.path) {
                this.$message.warning('请先上传收款码');
                return;
              }
              user.uploadCollectionCode({
                userId: this.user.userId,
                path: this.uploadConfig.data.wxCode.path,
                type: 'wxCode',
              }).then(res => {
                this.$message.success('上传成功');
                this.uploadConfig.data.wxCode.reUpload = true;
              });
            },
          },
          alipayCode: {
            auth: 0,
            path: '',
            reason: '',
            reUpload: false,
            label: '支付宝收款码',
            onSubmit: () => {
              if (!this.uploadConfig.data.alipayCode.path) {
                this.$message.warning('请先上传收款码');
                return;
              }
              user.uploadCollectionCode({
                userId: this.user.userId,
                path: this.uploadConfig.data.alipayCode.path,
                type: 'alipayCode',
              }).then(res => {
                this.$message.success('上传成功');
                this.uploadConfig.data.alipayCode.reUpload = true;
              });
            },
          },
        },
        loadData: () => {
          user.getCollectionCode({
            userId: this.user.userId
          }).then(res => {
            let {data: {wxCode, wxCode_auth, wxCode_reason, alipayCode, alipayCode_auth, alipayCode_reason, wxCodePreview, alipayCodePreview}} = res;
            this.uploadConfig.data.wxCode.path = wxCode;
            this.uploadConfig.data.wxCode.preview = wxCodePreview;
            this.uploadConfig.data.wxCode.auth = wxCode_auth;
            this.uploadConfig.data.wxCode.reason = wxCode_reason;
            this.uploadConfig.data.wxCode.reUpload = !!wxCode;
            this.uploadConfig.data.alipayCode.path = alipayCode;
            this.uploadConfig.data.alipayCode.preview = alipayCodePreview;
            this.uploadConfig.data.alipayCode.auth = alipayCode_auth;
            this.uploadConfig.data.alipayCode.reason = alipayCode_reason;
            this.uploadConfig.data.alipayCode.reUpload = !!alipayCode;
          })
        },
        UPLOAD_API: config.UPLOAD_API,
        uploadEnum,
        onUploadSuccess: ({key}, response, file, fileList) => {
          this.uploadConfig.data[key].path = response.src;
          this.uploadConfig.data[key].preview = response.preview;
        },
        uploadFileError: () => {
          this.$message.error('文件上传失败');
        },
        beforeUpload: ({key}, file) => {
          const allowType = [
            'image/png',
            'image/jpeg'
          ];
          const isMatchType =
            allowType.some(type => file.type.startsWith(type))
          ;
          const isLtSize = file.size / 1024 / 1024 < 1;
          if (!isMatchType) {
            this.$message.error('上传的文件只能是 图片 格式!');
            return false;
          }
          if (!isLtSize) {
            this.$message.error('上传的文件大小不能超过 1M!');
            return false;
          }
          return isMatchType && isLtSize && this.$message('文件正在上传') && true
        },
      }
    }
  },
  beforeMount() {
    this.uploadConfig.loadData();
  }
}
</script>

<style scoped lang="scss">

</style>
